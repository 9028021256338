<template>
  <div v-loading="loading">
    <div>
      <el-form
        size="mini"
        label-position="left"
        label-width="180px"
      >
        <el-form-item label="Название">
          <div style="display: flex">
            <click-to-edit
              style="width: 100%"
              v-model="template.name"
              :disabled="updateDisabled"
              @input="updateScaleTemplate"
            ></click-to-edit>
            <div style="margin-left:30px;width: 100px">ID: {{template.id}}</div>
          </div>
        </el-form-item>

        <el-form-item label="Описание" v-show="visible">
          <click-to-edit
            v-model="template.description"
            :disabled="updateDisabled"
            style="width: 100%; overflow-wrap: break-word;"
            type="textarea"
            @input="updateScaleTemplate"
          ></click-to-edit>
        </el-form-item>
        <el-form-item label="Тип шкалы" v-show="visible">
          <el-select
            v-model="template.type"
            :disabled="updateDisabled"
            size="mini"
            style="width: 100%"
            @change="updateScaleTemplate"
          >
            <el-option
              v-for="(scaleTypeConfig, scaleKey) in $companyConfiguration.scales.getConfigurableScaleConfig().types"
              :key="scaleKey"
              :value="scaleKey"
              :label="scaleTypeConfig.showName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Разрешить редактировать критерии в целях" v-show="visible && scalesScopesTemplate">
          <template v-slot:label>
            Разрешить редактировать критерии в целях
            <el-popover
              style=""
              placement="top"
              width="400"
              trigger="click"
            >
              <el-button
                slot="reference"
                class="table-action-button danger"
                type="text"
                round
                size="mini"
                icon="fas fa-info-circle"
              ></el-button>

              <div style="white-space: pre-line">У показателя будут самостоятельные критерии, которые пользователь сможет
                редактировать по своему усмотрению. Критерии из библиотеки будут добавлены для примера.
              </div>
              <br>
              <div style="white-space: pre-line">Изменение данного параметра ничего не меняет в уже настроенных
                показателях. Просто в следующем выборе факта будут учитываться вручную настроенные критерии
              </div>
            </el-popover>
          </template>

          <el-switch
            v-model="template.allow_scopes_edit_in_element"
            :disabled="updateDisabled"
            size="mini"
            @change="updateScaleTemplate"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>

    <component
      v-show="visible"
      :is="scalesScopesTemplate"
      :scale="template"
      :disabled="updateDisabled"
      @add-scope="saveScope({})"
      @save-scope="saveScope"
      @delete-scope="deleteScope"
    ></component>

    <div style="display: flex; justify-content: space-between; margin-top: 20px">
      <div>
        <el-button
          class="scales-settings-visibility"
          size="mini"
          :icon="visible ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
          @click="toggleTemplateVisibility"
        >
          {{ visible ? 'Свернуть': 'Развернуть' }}
        </el-button>
      </div>

      <div>
        <el-button
          v-if="!updateDisabled"
          size="mini"
          icon="fas fa-sync"
          @click="recountRelatedElements"
        >
          Пересчитать цели в активных картах
        </el-button>
      </div>


      <div>
        <el-switch
          v-model="template.disabled"
          :disabled="updateDisabled"
          :active-value="false"
          :inactive-value="true"
          active-text="Активная"
          @change="updateScaleTemplate"
        >
        </el-switch>

        <el-popconfirm
          v-if="$canAndRulesPass(template, $permissions.CONFIGURABLE_SCALES.DELETE)"
          style="margin-left: 15px"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить шкалу?"
          @confirm="deleteScaleTemplate"
        >
          <el-button
            slot="reference"
            size="mini"
            plain
            icon="far fa-trash-alt"
            :loading="loading"
          >
            Удалить
          </el-button>
        </el-popconfirm>
      </div>


    </div>




  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "scale-template",
  components: {LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {
    template: {type: Object, required: true},
  },
  watch: {},
  computed: {
    ...mapGetters(['user']),
    scalesScopesTemplate() {
      return this.$companyConfiguration.scales.getConfigurableScaleSettingsTemplate(this.template.type);
    },
    updateDisabled(){
      return !this.$canAndRulesPass(this.template, this.$permissions.CONFIGURABLE_SCALES.UPDATE);
    }
  },
  data() {
    return {
      loading: false,
      visible: false,

      scaleTemplates: [],
    }
  },
  mounted() {
      const visibleSettings = JSON.parse( localStorage.getItem('scaleTemplatesVisibility') || '{}' );
      this.visible = visibleSettings[this.template.id] || false;
  },
  methods: {
    toggleTemplateVisibility(){
      this.visible = !this.visible;
      let settings = JSON.parse( localStorage.getItem('scaleTemplatesVisibility') || '{}' );
      settings[this.template.id] = this.visible;
      localStorage.setItem('scaleTemplatesVisibility', JSON.stringify(settings));
    },

    updateScaleTemplate() {
      this.loading = true;
      requestSender('post', 'scale/update', this.template)
        .then(data => {
          this.$set(this.template, 'scopes', data.scale.scopes)
          this.$emit('update:template', data.scale);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteScaleTemplate() {
      this.loading = true;
      requestSender('post', 'scale/delete', {
        id: this.template.id,
      })
        .then(data => {
          this.$emit('template-deleted', data.template);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    saveScope(scope) {
      this.loading = true;
      requestSender('post', 'scale/save-scale-scope', {
        scale_id: this.template.id,
        scope_id: scope.id,
        data: scope,
      })
        .then(data => {
          this.$set(this.template, 'scopes', data.scale.scopes);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteScope(scope) {
      this.loading = true;
      requestSender('post', 'scale/delete-scale-scope', {
        scale_id: this.template.id,
        scope_id: scope.id,
      })
        .then(data => {
          this.$set(this.template, 'scopes', data.scale.scopes);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    recountRelatedElements() {
      this.loading = true;
      requestSender('post', 'scale/recount-related-elements', {
        scale_id: this.template.id,
      })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>


<style lang="scss" scoped>
//.scales-settings-visibility{
//  padding: 5px 10px 5px 0;
//  cursor: pointer;
//}
</style>